import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93')
];

export const server_loads = [];

export const dictionary = {
		"/": [15],
		"/(user)/(organizer)/(admin)/admin": [20,[2,3,4,6]],
		"/(user)/circles": [24,[2]],
		"/(user)/circles/create": [36,[2]],
		"/(user)/circles/[circleId]": [25,[2,7]],
		"/(user)/circles/[circleId]/announcements": [26,[2,7]],
		"/(user)/circles/[circleId]/create": [27,[2,7]],
		"/(user)/circles/[circleId]/dashboard": [28,[2,7]],
		"/(user)/circles/[circleId]/documents": [29,[2,7]],
		"/(user)/circles/[circleId]/events": [30,[2,7]],
		"/(user)/circles/[circleId]/events/create": [31,[2]],
		"/(user)/circles/[circleId]/following": [32,[2,7]],
		"/(user)/circles/[circleId]/innerCircles": [33,[2,7]],
		"/(user)/circles/[circleId]/members": [34,[2,7]],
		"/(user)/circles/[circleId]/posts": [35,[2,7]],
		"/(user)/(organizer)/(admin)/(super-admin)/companies": [17,[2,3,4,5]],
		"/(user)/(organizer)/(admin)/(super-admin)/companies/add": [18,[2,3,4,5]],
		"/(user)/(organizer)/(admin)/(super-admin)/companies/curate-images": [19,[2,3,4,5]],
		"/(user)/(super-admin)/companies/[name]/settings": [22,[2]],
		"/(user)/(super-admin)/companies/[name]/users/[role]": [23,[2]],
		"/(user)/create-account": [37,[2,8]],
		"/(user)/(organizer)/dashboard": [21,[2,3]],
		"/(user)/discover": [38,[2,9]],
		"/(user)/discover/all": [39,[2,9]],
		"/(user)/discover/courses": [40,[2,9]],
		"/(user)/discover/near-you": [41,[2,9]],
		"/(user)/discover/new": [42,[2,9]],
		"/(user)/discover/posts": [43,[2,9]],
		"/(user)/discover/recommended": [44,[2,9]],
		"/(user)/discover/searchResults": [45,[2,9]],
		"/(user)/documents/[postId]": [46,[2]],
		"/(anonymous)/eventprompts/[id]": [16],
		"/(user)/events": [47,[2]],
		"/(user)/events/all": [49,[2,10]],
		"/(user)/events/all/calendar": [50,[2,10]],
		"/(user)/events/all/past": [51,[2,10]],
		"/(user)/events/all/upcoming": [52,[2,10]],
		"/(user)/events/create": [53,[2]],
		"/(user)/events/[eventId]": [48,[2]],
		"/(user)/home": [54,[2,11]],
		"/(user)/home/announcements": [55,[2,11]],
		"/(user)/home/documents": [56,[2,11]],
		"/(user)/home/events": [57,[2,11]],
		"/(user)/home/events/create": [58,[2]],
		"/(user)/home/following": [59,[2,11]],
		"/(user)/home/posts": [60,[2,11]],
		"/(user)/learning": [61,[2]],
		"/(user)/learning/create": [62,[2]],
		"/(user)/logged-out": [63,[2]],
		"/(user)/organization": [~64,[2,12]],
		"/(user)/organization/interests": [66,[2,12]],
		"/(user)/organization/moderation": [67,[2,12]],
		"/(user)/organization/skills": [68,[2,12]],
		"/(user)/organization/values": [69,[2,12]],
		"/(user)/organization/[view]": [65,[2,12]],
		"/(user)/posts/[postId]": [70,[2]],
		"/(user)/profile": [71,[2]],
		"/(user)/profile/interests": [72,[2]],
		"/(user)/profile/languages": [73,[2]],
		"/(user)/profile/location": [74,[2]],
		"/(user)/profile/readyToMentorSkills": [75,[2]],
		"/(user)/profile/skills": [76,[2]],
		"/(user)/profile/wantToLearnSkills": [77,[2]],
		"/(user)/scheduled": [78,[2]],
		"/(user)/scheduled/join": [79,[2]],
		"/(user)/scheduled/new-videocall": [80,[2]],
		"/(user)/settings": [81,[2,13]],
		"/(user)/talent": [82,[2,14]],
		"/(user)/talent/[view]": [83,[2,14]],
		"/(user)/user/[userId]": [84,[2]],
		"/(user)/videocycle": [85,[2]],
		"/(user)/videocycle/break": [86,[2]],
		"/(user)/videocycle/chapter": [87,[2]],
		"/(user)/videocycle/done": [88,[2]],
		"/(user)/videocycle/join": [89,[2]],
		"/(user)/videocycle/new-videocall": [90,[2]],
		"/(user)/videocycle/quiz": [91,[2]],
		"/(user)/videocycle/waiting": [92,[2]],
		"/(user)/videocycle/welcome": [93,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
	init: client_hooks.init,
	reroute: (() => {}),
	transport: {}
};

export const decoders = Object.fromEntries(Object.entries(hooks.transport).map(([k, v]) => [k, v.decode]));

export const decode = (type, value) => decoders[type](value);

export { default as root } from '../root.svelte';